import React from "react";

const ArrowSVG = ({ small = false, down = false, fill = "white", ...rest }) => {
	if (small) {
		return (
			<svg
				{...rest}
				width="45"
				height="45"
				viewBox="0 0 45 45"
				fill="none"
				xmlns="http://www.w3.org/2000/svg">
				<g transform={down ? "rotate(180,22.5,22.5)" : ""}>
					<path
						d="M3.99989 6.18172e-07L39.9999 6.91261e-06C42.7613 7.39544e-06 44.9999 2.23858 44.9999 5.00001L44.9999 41L34.9999 41L34.9999 17.0711L7.53541 44.5355L0.464349 37.4645L27.9288 10L3.99989 10L3.99989 6.18172e-07Z"
						fill={fill}
					/>
				</g>
			</svg>
		);
	}

	return (
		<svg
			{...rest}
			width="110"
			height="110"
			viewBox="0 0 110 110"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<g transform={down ? "rotate(180,55,55)" : ""}>
				<path
					d="M90 34.1421L90 110L110 110L110 10C110 4.47715 105.523 -3.91405e-07 100 -8.74228e-07L9.61651e-06 -9.61651e-06L7.86805e-06 20L75.8579 20L2.92893 92.9289L17.0711 107.071L90 34.1421Z"
					fill={fill}
				/>
			</g>
		</svg>
	);
};

export default ArrowSVG;
