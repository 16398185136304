import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
	z-index: 3;
	position: relative;
`;

const Content = styled.div``;
const BodyCopy = ({ content }) => {
	return (
		<Container>
			<Content dangerouslySetInnerHTML={{ __html: content }} />
		</Container>
	);
};

export default BodyCopy;
