import React, { useEffect, useMemo, useRef } from "react";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import importDirectory from "../util/importDirectory";
import Seo from "../components/Seo";
import { PageLayout } from "../contexts/PageLayout";
import { useResizer } from "../contexts/Resizer";
import { useUIState } from "../contexts/UIStore";
import { Helmet } from "react-helmet";
import shareImage from "../images/social-mobility-act-3-OG.png";
import { motion } from "framer-motion";

let chapterLayouts = importDirectory(
	require.context(
		"../components/Act3/ChapterLayout/",
		true,
		/[a-zA-Z_-]*\.js$/
	),
	"default"
);

const Container = styled.div`
	//position: relative;
	//z-index: 2;
	//color: #f3eeeb;
	display: flex;
	flex-direction: column;
`;
const ForecastingFragility = ({
	data: {
		allAct3Yaml: { nodes },
	},
}) => {
	const ref = useRef();
	const uiState = useUIState();
	const setArea = useResizer();

	useEffect(() => {
		uiState.setGrain(false);
		uiState.setLockNav(true);

		uiState.addTheme(
			{
				foreground: "#ffffff",
				background: "#0B0B0B",
			},
			"forecasting-fragility"
		);

		return () => {
			uiState.removeTheme("forecasting-fragility");
		};
	}, [uiState]);

	useEffect(() => {
		let timeout;

		const observer = new ResizeObserver((entries) => {
			for (let entry of entries) {
				clearTimeout(timeout);
				let width, height;
				if (entry.borderBoxSize) {
					const borderBoxSize = Array.isArray(entry.borderBoxSize)
						? entry.borderBoxSize[0]
						: entry.borderBoxSize;

					width = borderBoxSize.inlineSize;
					height = borderBoxSize.blockSize;
				} else {
					width = entry.contentRect.width;
					height = entry.contentRect.height;
				}
				timeout = setTimeout(() => {
					setArea((area) => {
						if (area.width !== width || area.height !== height) {
							return { width, height };
						} else {
							return area;
						}
					});
					uiState.setWindowDimensions(window.innerHeight, window.innerWidth);
				}, 150);
			}
		});
		observer.observe(ref.current);
		return () => {
			observer.disconnect();
		};
	}, [uiState, setArea]);
	return (
		<motion.div ref={ref}>
			<Seo
				title="Forecasting Fragility"
				image={shareImage}
				description={
					"Measuring the economic sustainability and fragility of a country."
				}
			/>
			<PageLayout>
				<Helmet bodyAttributes={{ class: "no-grain" }} />
				<Container>
					{nodes.map((node, i) => {
						let Component = chapterLayouts[node.component];
						return <Component key={node.keyId} data={node} index={i + 1} />;
					})}
				</Container>
			</PageLayout>
		</motion.div>
	);
};

export const pageQuery = graphql`
	query Act3 {
		allAct3Yaml(sort: { fields: order, order: ASC }) {
			nodes {
				title
				menuTitle
				order
				keyId
				subtitle
				component
				theme {
					background
					foreground
				}
				body {
					component
					content
					countries {
						country
						description
					}
					heading
					subHeading
					lead
					info
					data {
						childrenRanksAndScoresCsv {
							country
							_2012_ep_rank
							_2012_col_rank
							_2012_fs_rank
							_2012_barometer
							_2012_childcare_score
							_2012_housing_score
							_2012_education_score
							_2012_health_score
							_2020_ep_rank
							_2020_col_rank
							_2020_fs_rank
							_2020_barometer
							_2020_childcare_score
							_2020_housing_score
							_2020_education_score
							_2020_health_score
							fragility
						}
					}
				}
			}
		}
	}
`;

export default ForecastingFragility;
