import React, { useMemo, useState, useRef } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { orderBy } from "lodash";
import { grid, fontSize, spacing, screen } from "../../../styles/mixins";
import { fontSurt, salty, noSalt } from "../../../styles/typography";

import { useUIState } from "../../../contexts/UIStore";
import { observer } from "mobx-react-lite";
import howToGraphic from "../../../images/howto_desktop.svg";
import howToGraphicM from "../../../images/howto_laptop.svg";
import howToGraphicS from "../../../images/howto_mobile.svg";

const colors = {
	red: "#FF542F",
	redBG: "rgba(255,84,47,.2)",
	yellow: "#FFE600",
	yellowBG: "rgba(255,230,0,.12)",
	green: "#00FFAA",
	greenBG: "rgba(0,255,170,.12)",
	border: "#44444B",
	grey: "#61616B",
	tableHeader: "#1C1C1C",
	blue: "#6AB7FF",
	black: "#0B0B0B",
};

const Main = styled.div`
	width: 100%;
	position: relative;
	background: ${colors.black};
	min-height: 100vh;
	display: flex;
	flex-direction: column;

	${salty}

	.no-salt {
		${noSalt}
	}

	.lead {
		${fontSize(1.5)}
	}

	p {
		${spacing("marginBottom", "s")}
	}

	h3 {
		${spacing("paddingBottom", "m")}
		${spacing("paddingTop", "m")}
	}
`;

const Row = styled.div`
	width: 100%;
	${grid({})}
	position: relative;
	z-index: 2;

	&:nth-child(2) {
		flex-grow: 1;
	}
`;

const Col1 = styled.div`
	grid-column: 2 / -2;
	display: flex;
	flex-direction: column;
	${grid({ cols: 18 })}

	${spacing("paddingTop", "l")}

	@media ${screen("md")} {
		grid-column: 2 / -14;
	}

	@media ${screen("lg")} {
		grid-column: 2 / span 18;
	}
`;

const Heading = styled.h2`
	grid-column: 1 / -1;
	line-height: 105%;
	${spacing("paddingBottom", "m")}
`;

const Copy = styled.div`
	grid-column: 1 / -1;

	@media ${screen("md")} {
		grid-column: 1 / span 16;
	}
`;

const HowTo = styled.div`
	grid-column: 6 / -6;
	${spacing("paddingTop", "l")}
	${spacing("paddingBottom", "l")}

	margin: auto 0;
	flex-grow: 1;
`;

const HowToImg = styled.img`
	width: 100%;
	display: none;

	@media ${screen("lg")} {
		display: block;
	}
`;
const HowToImgM = styled.img`
	width: 100%;
	display: none;

	@media ${screen("md")} {
		display: block;
	}

	@media ${screen("lg")} {
		display: none;
	}
`;
const HowToImgS = styled.img`
	width: 100%;
	display: block;

	@media ${screen("md")} {
		display: none;
	}
`;

const BarometerHowTo = observer(({ heading, content }) => {
	const uiState = useUIState();

	return (
		<Main>
			<Row>
				<Col1>
					<Heading
						className={"lead"}
						dangerouslySetInnerHTML={{ __html: heading }}
					/>
					<Copy dangerouslySetInnerHTML={{ __html: content }} />
				</Col1>
			</Row>

			<Row>
				<HowTo>
					<HowToImg src={howToGraphic} />
					<HowToImgM src={howToGraphicM} />
					<HowToImgS src={howToGraphicS} />
				</HowTo>
			</Row>
		</Main>
	);
});

export default BarometerHowTo;
