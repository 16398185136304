import React, { useMemo, useState, useRef } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { orderBy } from "lodash";
import { grid, fontSize, spacing, screen } from "../../../styles/mixins";
import { fontSurt, salty, noSalt } from "../../../styles/typography";

import { useUIState } from "../../../contexts/UIStore";
import { observer } from "mobx-react-lite";

import leadGraphic1 from "../../../images/rhombus-1.svg";
import contentGraphic2 from "../../../images/rhombus-2.svg";
import contentGraphic from "../../../images/rhombus-3.svg";

import contentGraphicSm from "../../../images/economy-graphic.svg";

const colors = {
	red: "#FF542F",
	redBG: "rgba(255,84,47,.2)",
	yellow: "#FFE600",
	yellowBG: "rgba(255,230,0,.12)",
	green: "#00FFAA",
	greenBG: "rgba(0,255,170,.12)",
	border: "#44444B",
	grey: "#61616B",
	tableHeader: "#1C1C1C",
	blue: "#6AB7FF",
	black: "#0B0B0B",
};

const Main = styled.div`
	width: 100%;
	position: relative;
	z-index: 1;
	/* padding-top: ${({ navHeight }) => navHeight}px; */
	border-bottom: 1px solid white;
	${salty}

	.lead {
		${fontSize(1.5)}
		line-height:105%;
	}

	.list {
		${spacing("paddingTop", "l")}
		${spacing("paddingBottom", "l")}

		span {
			display: inline-block;
			padding-left: 7em;
			position: relative;
			${spacing("marginTop", "s")}
			${spacing("marginBottom", "s")}

			&:before {
				content: attr(data-num);
				position: absolute;
				left: 0;
				top: 0;
				display: inline-block;
				${noSalt}
			}
		}
	}

	p {
		${spacing("marginBottom", "s")}
	}
`;

const MainHeading = styled.h2`
	grid-column: 2 / -2;
	flex-grow: 0;
	${fontSize(3)}
	line-height:102%;

	@media ${screen("lg")} {
		${fontSize(4.5)}
	}
`;

const HeadingWrap = styled.div`
	${grid({ cols: 23 })}
	width: 100%;
`;
const Row = styled.div`
	width: 100%;
	${grid({})} /* ${spacing("paddingTop", "m")} */
	z-index: 2;
`;

const Col1 = styled.div`
	/* grid-column: 1 / span 24; */
	display: flex;
	flex-direction: column;
	/* ${grid({ cols: 23 })} */

	${spacing("paddingTop", "m")}

	grid-column: 1 / -1;

	@media ${screen("md")} {
		padding-left: 0;
		padding-right: 0;

		grid-column: 1 / span 20;
	}

	@media ${screen("lg")} {
		grid-column: 1 / span 22;
	}

	@media ${screen("ms")} {
		grid-column: 1 / span 24;
	}
`;

const Col2 = styled.div`
	grid-column: 25 / span 19;
	${spacing("paddingTop", "m")}

	${spacing("paddingLeft", "s")}
	${spacing("paddingRight", "s")}

	background-image: url("${contentGraphicSm}");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: right bottom;

	grid-column: 1 / -1;

	@media ${screen("md")} {
		background-image: url("${contentGraphic}");
		grid-column: 21 / -4;

		border-left: 1px solid white;
		border-right: 1px solid white;
		${spacing("paddingLeft", "s")}
		${spacing("paddingRight", "s")}
	}

	@media ${screen("lg")} {
		grid-column: 23 / span 19;
	}

	@media ${screen("ms")} {
		grid-column: 25 / span 19;
	}
`;

const Col3 = styled.div`
	display: none;

	background-image: url("${contentGraphic2}");
	background-size: contain;
	background-repeat: no-repeat;
	background-position: right bottom;

	@media ${screen("ms")} {
		display: block;
		grid-column: -6 / -1;
	}
`;

const LeadGrow = styled.div`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	width: 100%;

	background-image: url("${leadGraphic1}");
	background-size: 100% auto;
	background-position: left bottom;
	background-repeat: no-repeat;
`;

const ContentGraphicPad = styled.div`
	width: 100%;
	padding-bottom: 200%;

	@media ${screen("md")} {
		padding-bottom: 100%;
	}
`;

const EquitableEconomy = observer(({ heading, subHeading, lead, content }) => {
	const uiState = useUIState();

	return (
		<Main navHeight={uiState.navHeight}>
			<Row>
				<Col1>
					<HeadingWrap>
						<MainHeading dangerouslySetInnerHTML={{ __html: heading }} />
					</HeadingWrap>
					<LeadGrow></LeadGrow>
				</Col1>
				<Col2>
					<div dangerouslySetInnerHTML={{ __html: content }}></div>
					<ContentGraphicPad />
				</Col2>
				<Col3></Col3>
			</Row>
		</Main>
	);
});

export default EquitableEconomy;
