import React, { useMemo, useState, useRef } from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

import { orderBy } from "lodash";
import { grid, fontSize, spacing, screen } from "../../../styles/mixins";
import { fontSurt, salty, noSalt } from "../../../styles/typography";

import { useUIState } from "../../../contexts/UIStore";
import { observer } from "mobx-react-lite";

import introArrowGraphic from "../../../images/intro-arrow-graphic.svg";
import circlesGraphic from "../../../images/circles-graphic.svg";
import circlesGraphic1 from "../../../images/circles-graphic1.svg";
import circlesGraphic2 from "../../../images/circles-graphic2.svg";
import movingUpImg from "../../../images/arrow_better.svg";

const colors = {
	red: "#FF542F",
	redBG: "rgba(255,84,47,.2)",
	yellow: "#FFE600",
	yellowBG: "rgba(255,230,0,.12)",
	green: "#00FFAA",
	greenBG: "rgba(0,255,170,.12)",
	border: "#44444B",
	grey: "#61616B",
	tableHeader: "#242222",
	blue: "#6AB7FF",
	black: "#0B0B0B",
};

const Main = styled.div`
	width: 100%;
	padding-top: ${({ navHeight }) => navHeight}px;
	${salty}

	.lead {
		${fontSize(1.5)}
		line-height:105%;
	}

	p {
		${spacing("marginBottom", "s")}
	}

	.no-salt {
		${noSalt}
	}

	.bb {
		border-bottom: 1px solid white;
		${spacing("paddingBottom", "m")}

		@media ${screen("md")} {
			/* padding-bottom: 0; */
			border-bottom: none;
		}
	}
`;

const MainHeading = styled.h1`
	width: 100%;
	${spacing("paddingBottom", "l")}
	${fontSize(5)}
	line-height:101%;

	@media ${screen("md")} {
		${fontSize(4.5)}
	}

	@media ${screen("lg")} {
		${fontSize(6)}
	}

	@media ${screen("ms")} {
		${fontSize(6.5)}
	}

	@media ${screen("mx")} {
		${fontSize(7)}
	}
`;

const Row = styled.div`
	width: 100%;
	${grid({})}
`;

const Col1 = styled.div`
	${spacing("paddingLeft", "m")}
	${spacing("paddingRight", "m")}

	${spacing("paddingTop", "m")}

	&.align-bottom {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	grid-column: 1 / -1;

	@media ${screen("md")} {
		padding-left: 0;
		padding-right: 0;

		grid-column: 2 / span 18;
	}

	@media ${screen("lg")} {
		grid-column: 2 / span 20;
	}

	@media ${screen("ms")} {
		grid-column: 2 / span 22;
	}
`;

const Col2 = styled.div`
	${spacing("paddingLeft", "m")}
	${spacing("paddingRight", "m")}



	grid-column: 1 / -1;

	${spacing("paddingTop", "m")}

	&.bottom {
		padding-top: 0;
	}

	@media ${screen("md")} {
		grid-column: 21 / -4;

		border-left: 1px solid white;
		border-right: 1px solid white;
		${spacing("paddingLeft", "s")}
		${spacing("paddingRight", "s")}
	}

	@media ${screen("lg")} {
		grid-column: 23 / span 19;
	}

	@media ${screen("ms")} {
		grid-column: 25 / span 19;
	}
`;

const Col3 = styled.div`
	${spacing("paddingLeft", "m")}
	${spacing("paddingRight", "m")}

	${spacing("paddingTop", "m")}
	${spacing("paddingBottom", "m")}
	grid-column: -6 / span 5;

	overflow-x: hidden;

	grid-column: 1 / -1;

	@media ${screen("md")} {
		grid-column: 21 / -4;

		border-left: 1px solid white;
		border-right: 1px solid white;
		${spacing("paddingLeft", "s")}
		${spacing("paddingRight", "s")}
	}

	@media ${screen("lg")} {
		grid-column: -8 / -1;

		border: none;
	}

	@media ${screen("ms")} {
		grid-column: -6 / -1;
	}
`;

const IntroArrow = styled.img`
	width: 100%;
	${spacing("paddingBottom", "m")}
	grid-column: 25 / span 19;
	max-width: 400px;

	@media ${screen("md")} {
		max-width: inherit;
	}
`;

const Circles = styled(Row)`
	background-image: url("${circlesGraphic}");
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center center;

	@media ${screen("md")} {
		background-image: url("${circlesGraphic1}"), url("${circlesGraphic2}");
		background-size: 41% auto, 68% auto;
		background-position: -28% center, 186.3% center;
	}

	@media ${screen("lg")} {
		background-size: 41% auto, 68% auto;
		background-position: 4% center, 161% center;
	}

	@media ${screen("ms")} {
		background-size: 46% auto, 74% auto;
		background-position: 3.2% center, 202% center;
	}
`;

const CirclesPad = styled.div`
	padding-bottom: 60%;

	@media ${screen("md")} {
		padding-bottom: 120%;
	}
`;

const Links = styled.div`
	${spacing("paddingTop", "s")}
`;

const PageLink = styled(Link)`
	color: ${colors.green};
	width: 100%;
	display: inline-block;
	line-height: 180%;

	background-image: url(${movingUpImg});
	background-position: right 0.3em;
	background-repeat: no-repeat;
	background-size: 0.9em;
`;

const InPageLink = styled(Link)`
	color: ${colors.green};
	width: 100%;
	display: inline-block;
	${spacing("paddingTop", "m")}
`;

const Intro = observer(({ heading, subHeading, lead, content, info }) => {
	const uiState = useUIState();

	return (
		<Main navHeight={uiState.navHeight}>
			<Row>
				<Col1 className={"bb"}>
					<MainHeading dangerouslySetInnerHTML={{ __html: heading }} />
				</Col1>
				<Col2 className={"bb"}>
					<div dangerouslySetInnerHTML={{ __html: subHeading }}></div>
					<InPageLink className="lead" to="#barometer">
						See the Barometer
					</InPageLink>
				</Col2>
				<Col3 className={"bb"}>
					<div dangerouslySetInnerHTML={{ __html: info }}></div>
					<Links>
						<PageLink to="/a-promise-broken/">Read Part 01</PageLink>
						<PageLink to="/economic-opportunities-for-our-avatars/">
							Read Part 02
						</PageLink>
					</Links>
				</Col3>
			</Row>

			<Row>
				<Col1 className={"bb"}>
					<IntroArrow src={introArrowGraphic} />
				</Col1>
				<Col2></Col2>
				<Col1
					dangerouslySetInnerHTML={{ __html: lead }}
					className={"align-bottom"}></Col1>
				<Col2
					className={"bottom"}
					dangerouslySetInnerHTML={{ __html: content }}></Col2>
			</Row>

			<Circles>
				<Col1>
					<CirclesPad />
				</Col1>
				<Col2></Col2>
			</Circles>
		</Main>
	);
});

export default Intro;
