import React, { useCallback, useEffect, useRef } from "react";
import importDirectory from "../../../util/importDirectory";
import styled from "@emotion/styled";
import { ThemeProvider } from "@emotion/react";
import { useUIState } from "../../../contexts/UIStore";
import { fontSurt } from "../../../styles/typography";

//Has title and loops subsections
const Container = styled.div`
	${fontSurt}

	padding-bottom: ${({ spacingBottom }) => spacingBottom ?? null};
	${({ theme }) => ({
		backgroundColor: theme.background,
		color: theme.foreground,
	})};
`;
let features = importDirectory(
	require.context("../", true, /Feature\/[a-zA-Z0-9_-]*\.js$/),
	"default"
);
let structure = importDirectory(
	require.context("../", true, /Structure\/[a-zA-Z0-9_-]*\.js$/),
	"default"
);
let components = { ...features, ...structure };

const Base = ({ data, index }) => {
	const uiState = useUIState();
	const localRef = useRef();

	let combinedRef = useCallback(
		(node) => {
			localRef.current = node;
		},
		[localRef]
	);

	return (
		<ThemeProvider theme={{ ...(data.theme ?? {}) }}>
			<Container ref={combinedRef}>
				{data.body &&
					data.body.map((block, i) => {
						let Component = components[block.component];
						if (Component) {
							return <Component {...block} key={i} />;
						} else {
							return <div key={i}>Missing Component: {block.component}</div>;
						}
					})}
			</Container>
		</ThemeProvider>
	);
};

export default Base;
